import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import LogoImage from '@/public/logos/shotgrid/ShotGrid-2023-lockup-Blk-OL-No-Year-1-line.png';

interface LogoProps {
  width?: number;
  height?: number;
}

const Logo: FC<LogoProps> = ({ width = 164, height = 70 }) => {
  const { t } = useTranslation('common');

  const productName = t('product-name-mixedcase', {
    applyPostProcessor: false,
  }).toLowerCase();

  return (
    <div>
      <Image src={LogoImage} alt={productName} width={width} height={height} />
    </div>
  );
};

Logo.defaultProps = {
  width: 260,
  height: 78,
};

export default Logo;
