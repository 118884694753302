import { useTranslation } from 'next-i18next';
import React from 'react';

import { SvgIcon } from '@sgds/component-svg-icon';
import { TextPresentation } from '@sgds/component-text';
import { TextLink } from '@sgds/component-text-link';

const styleSheetFunc = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 612,
    margin: 12,
  },
  errorHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  errorContent: {
    margin: 0,
    lineHeight: 2,
  },
  alertStyle: {
    color: '#faa21b',
    height: 24,
    width: 24,
    marginRight: 10,
  },
});

const ErrorAccessReleases = (): JSX.Element => {
  const styleSheet = styleSheetFunc();
  const { t } = useTranslation(['mainPage', 'accessReleases']);

  return (
    <div style={{ flexDirection: 'column', ...styleSheet.container }}>
      <div style={{ flexDirection: 'row', ...styleSheet.errorHeader }}>
        <SvgIcon iconId="alert" style={styleSheet.alertStyle} />{' '}
        <TextPresentation isStrong variant={TextPresentation.variants.BODY}>
          {t('error-message')}
        </TextPresentation>
      </div>
      <TextPresentation
        variant={TextPresentation.variants.BODY}
        style={styleSheet.errorContent}
      >
        {t('error-detail')}{' '}
        <TextLink
          href={t('common:support-url')}
          label={t('support-site-label')}
          target="_blank"
        />
        .
      </TextPresentation>
    </div>
  );
};

export default ErrorAccessReleases;
