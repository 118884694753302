import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

interface UseWaitForUpdatedPropsCallbacks {
  refreshData: () => void;
  dataRefreshed: () => void;
}

const useWaitForUpdatedProps = (): UseWaitForUpdatedPropsCallbacks => {
  const router = useRouter();
  const [isRefreshingProps, setIsRefreshingProps] = useState(false); // still loading anything?

  const refreshData = useCallback(
    () =>
      isRefreshingProps
        ? false /* already refreshing */
        : setIsRefreshingProps(true),
    [isRefreshingProps]
  );

  const dataRefreshed = useCallback(() => {
    isRefreshingProps && setIsRefreshingProps(false);
  }, [isRefreshingProps]);

  /**
   * React side-effect - upon refreshData, rehydrate the component with updated props.
   */
  useEffect(() => {
    if (!router.isReady) {
      // can't re-hydrate yet.
      return;
    }

    if (!isRefreshingProps) {
      // re-hydration not required or has completed.
      return;
    }

    router.push(router.asPath, router.asPath, {
      shallow: false, // rehydrate
    });
  }, [router, isRefreshingProps]);

  return { refreshData, dataRefreshed };
};

export default useWaitForUpdatedProps;
