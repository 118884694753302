import { inspect } from 'util';

import AnyLogger, { Logger } from 'anylogger'; // Logging support - https://github.com/Download/anylogger#quickstart
import ulog from 'ulog';

import { initializeLogging } from '@/middlewares/ulog';
import * as Sentry from '@sentry/nextjs';

const inspectOptions = {};

/**
 * get logger for given subsystem
 * @param context {String} subsystem name
 */
const logger = (context: string): Logger => {
  initializeLogging();
  const loggerForCtx = AnyLogger(context || 'shothome');
  loggerForCtx.debug(ulog.levels);

  const helper =
    traceLevel =>
    (name, object = undefined) => {
      if (loggerForCtx.enabledFor(traceLevel)) {
        const objAsString = object
          ? inspect(object, inspectOptions)
          : undefined;

        const params = [name];
        if (objAsString) {
          params.push(objAsString);
        }

        // Intentionally follow active log level to add breadcrumbs.
        Sentry.addBreadcrumb({
          category: context,
          message: object,
          level: traceLevel,
        });

        const method = loggerForCtx[traceLevel];
        method.apply(loggerForCtx, params);
      }
    };

  return {
    name: loggerForCtx.name,
    error: helper('error'),
    warn: helper('warn'),
    info: helper('info'),
    log: helper('info'),
    /* @ts-expect-error added method */
    logQuery: helper('info'),
    logQueryError: helper('error'),
    debug: helper('debug'),
    trace: helper('trace'),
    enabledFor: loggerForCtx.enabledFor,
  };
};

export default logger;
