import { useTranslation } from 'next-i18next';
import React from 'react';
import semver from 'semver';

import { ReleaseCollection, ReleaseProduct } from '@/common/types';
import { getReleaseDownloadUrl } from '@/lib/releases_helpers';

import { TextPresentation } from '@sgds/component-text';
import { TextLink } from '@sgds/component-text-link';

import styles from './releaseProductElement.module.css';

const ReleaseProductElement = ({
  releases,
  releaseProduct,
}: {
  releases: ReleaseCollection;
  releaseProduct: ReleaseProduct;
}): JSX.Element => {
  const { t } = useTranslation('releaseProduct');

  const sortedReleases = releases.sort((left, right) => {
    const extractVersion = release => {
      const match = release.name.match(/[-v]\s*([\d+.]+\d)/);
      return semver.coerce(match[1], { loose: true });
    };
    return semver.compareBuild(
      extractVersion(right),
      extractVersion(left)
    ); /* reverse sort */
  });

  return (
    <div>
      <TextPresentation
        variant={TextPresentation.variants.HEADLINE_3}
        className={styles.contentTitle}
      >
        {releaseProduct.name}
      </TextPresentation>
      <TextPresentation
        variant={TextPresentation.variants.BODY}
        className={styles.content}
      >
        {t('body', { productName: releaseProduct.name })}
      </TextPresentation>
      <div className={styles.headerStyle}>
        <TextPresentation
          isStrong
          variant={TextPresentation.variants.BODY}
          className="mt-6 ml-2"
        >
          {t('releaseProduct:version-label')}
        </TextPresentation>
        <TextPresentation
          isStrong
          variant={TextPresentation.variants.BODY}
          className="mr-4.5"
        >
          {t('releaseProduct:download-link-label')}
        </TextPresentation>
      </div>
      <ul className={styles.releaseList}>
        {sortedReleases.map(release => (
          <li className={styles.release} key={release.name}>
            <span className={styles.releaseName}>{release.name}</span>
            <span className={styles.downloadContent}>
              <TextLink
                href={`${getReleaseDownloadUrl(release)}`}
                label={t('releaseProduct:download-label')}
                target="_self"
              />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ReleaseProductElement;
