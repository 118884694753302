import type { Release } from '@/common/types';

import * as URLS from './urls';

/**
 * Generate download URL for the release
 * @param release { name: String; product: String; signature: String; } Object with name, product and signature properties
 * @returns {String} Returns String URL with name, product and signature query params
 * @example /api/release?name=shotgun-release-1.tar&product=Shotgun Releases&signature=ObjectKeyHash
 */
const getReleaseDownloadUrl = (release: Release): string =>
  `${URLS.API_DOWNLOAD_RELEASE_ROUTE}?name=${release.name}&product=${release.product}&signature=${release.signature}`;

export { getReleaseDownloadUrl };
