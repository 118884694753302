// eslint-disable-next-line eslint-comments/no-restricted-disable -- see below
/* eslint-disable jsx-a11y/anchor-is-valid -- Link from next pass the href */

import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React from 'react';

import * as URLS from '@/lib/urls';

import { Card } from '@sgds/component-card';
import { SvgIcon } from '@sgds/component-svg-icon';
import { TextPresentation } from '@sgds/component-text';

import styles from './accessReleases.module.css';

const Title = (): JSX.Element => {
  const { t } = useTranslation('accessReleases');

  return (
    <div className={styles.contentTitle}>
      <TextPresentation variant={TextPresentation.variants.SUBHEAD_1}>
        {t('detail')}
      </TextPresentation>
    </div>
  );
};

const AccessReleasesCard = (): JSX.Element => {
  const { t } = useTranslation('accessReleases');

  return (
    <Link href={URLS.RELEASES_PAGE_ROUTE}>
      <a>
        <Card isInteractive className={styles.card}>
          <div className={styles.cardContent}>
            <TextPresentation className={styles.cardLinkStyle}>
              {t('table-title')}
            </TextPresentation>
          </div>
          <div className={styles.filler} />
          <div className={styles.cardCaretRight}>
            <SvgIcon iconId="caretRightDense" />
          </div>
        </Card>
      </a>
    </Link>
  );
};
const AccessReleases = (): JSX.Element => (
  <div className={styles.container}>
    <Title />
    <AccessReleasesCard />
  </div>
);

export default AccessReleases;
