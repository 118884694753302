import { useTranslation } from 'next-i18next';
import React from 'react';

import { ReleaseCatalog, ReleasePageProduct } from '@/common/types';
import Footer from '@/components/footer';
import Logo from '@/components/logo';

import { TextPresentation } from '@sgds/component-text';
import { TextLink } from '@sgds/component-text-link';

import styles from './releasePage.module.css';
import ReleaseProductElement from './releaseProductElement';

const ReleasePage = ({
  releasesCatalog,
  buildId,
}: {
  releasesCatalog: ReleaseCatalog;
  buildId: string;
}): JSX.Element => {
  const { t } = useTranslation(['releasePage', 'footer']);
  const releaseProducts: Array<ReleasePageProduct> = [
    // NOTE: prefixes are strings used on AWS S3 and thus not candidate to i18n.
    {
      prefix: 'Docker/Shotgun Releases',
      name: t('main-name'),
    },
    {
      prefix: 'Docker/Shotgun Enterprise Console Releases',
      name: t('enterprise-console-docker-name'),
    },
    {
      prefix: 'Docker/Transcoder Releases',
      name: t('transcoder-name'),
    },
    {
      prefix: 'Docker/Watermarker Releases',
      name: t('watermarker-name'),
    },
    {
      prefix: 'Unified Template Releases/Media Template Releases',
      name: `${t('unified-template-name')} - ${t('media-template-name')}`,
    },
  ];
  const createReleaseProducts: Array<ReleasePageProduct> = [
    {
      prefix: 'Shotgun Create Releases',
      name: t('create-name'),
    },
  ];
  const deprecatedReleaseProducts: Array<ReleasePageProduct> = [
    {
      prefix: 'Shotgun Releases',
      name: t('main-name'),
    },
    {
      prefix: 'Shotgun Beta Releases',
      name: t('beta-name'),
    },
    {
      prefix: 'Shotgun Enterprise Console Releases',
      name: t('enterprise-console-classic-name'),
    },
  ];

  return (
    <main>
      <section className={styles.container}>
        <header>
          <Logo />
        </header>
        <article>
          <header>
            <TextPresentation
              variant={TextPresentation.variants.HEADLINE_2}
              className={styles.contentTitle}
            >
              {t('title-docker')}
            </TextPresentation>

            <TextPresentation
              variant={TextPresentation.variants.BODY}
              className={[styles.content, 'mt-2'].join(' ')}
            >
              {t('detail-docker')}
            </TextPresentation>
          </header>

          <ul className={styles.list}>
            {releaseProducts.map(releaseProduct => (
              <li key={releaseProduct.prefix}>
                <ReleaseProductElement
                  releases={releasesCatalog[releaseProduct.prefix] || []}
                  releaseProduct={releaseProduct}
                />
              </li>
            ))}
          </ul>
        </article>

        <article>
          <header>
            <TextPresentation
              variant={TextPresentation.variants.HEADLINE_2}
              className={[styles.contentTitle, 'mt-12'].join(' ')}
            >
              {t('title-create')}
            </TextPresentation>
            <TextPresentation
              variant={TextPresentation.variants.BODY}
              className={[styles.content, 'mt-2'].join(' ')}
            >
              {t('detail-create')}
            </TextPresentation>
          </header>

          <ul className={styles.list}>
            {createReleaseProducts.map(releaseProduct => (
              <li key={releaseProduct.prefix}>
                <ReleaseProductElement
                  releases={releasesCatalog[releaseProduct.prefix] || []}
                  releaseProduct={releaseProduct}
                />
              </li>
            ))}
          </ul>
        </article>

        <article>
          <header>
            <TextPresentation
              variant={TextPresentation.variants.HEADLINE_2}
              className={[styles.contentTitle, 'mt-12'].join(' ')}
            >
              {t('title')}
            </TextPresentation>
            <TextPresentation
              variant={TextPresentation.variants.BODY}
              className={[styles.content, 'mt-2'].join(' ')}
            >
              {t('detail')}
            </TextPresentation>
            <TextPresentation
              variant={TextPresentation.variants.BODY}
              className={['mt-8', styles.content].join(' ')}
            >
              <span className="font-bold">{t('warning-title')}</span>{' '}
              {t('warning-message')}
            </TextPresentation>
          </header>

          <ul className={styles.list}>
            {deprecatedReleaseProducts.map(releaseProduct => (
              <li key={releaseProduct.prefix}>
                <ReleaseProductElement
                  releases={releasesCatalog[releaseProduct.prefix] || []}
                  releaseProduct={releaseProduct}
                />
              </li>
            ))}
          </ul>
        </article>

        <footer>
          <TextPresentation
            variant={TextPresentation.variants.HEADLINE_2}
            className={[styles.contentTitle, 'mt-12'].join(' ')}
          >
            {t('questions-title')}
          </TextPresentation>

          <TextPresentation
            variant={TextPresentation.variants.BODY}
            className={[styles.content, 'mt-8'].join(' ')}
          >
            {t('questions-message')}{' '}
            <TextLink
              href={t('common:support-url')}
              label={t('support-site-label')}
              target="_blank"
            />
          </TextPresentation>
        </footer>
      </section>
      <Footer buildId={buildId} />
    </main>
  );
};
export default ReleasePage;
