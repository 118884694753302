import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';

import { TextPresentation } from '@sgds/component-text';

import styles from './footer.module.css';

const Footer: FC<{ buildId: string }> = ({ buildId }) => {
  const { t } = useTranslation('footer');

  return (
    <div className={styles.container}>
      <span>
        <TextPresentation className={styles.text}>{buildId}</TextPresentation>
        {' | '}
        &copy;{' '}
        <TextPresentation className={styles.text}>
          {t('copyright', {
            copyrightRange: new Date().getFullYear(),
            applyPostProcessor: false,
          })}
        </TextPresentation>
      </span>
    </div>
  );
};

export default Footer;
